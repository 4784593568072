import React, { createContext, useState } from 'react';
import { IModalData, IModalSelectionData } from 'interfaces/Modal/Modal';

type TAuthAfterSuccess = 'activate' | 'showOrder' | '';

export interface IAppContext {
  modalType: string;
  setModalType?: (string) => void;
  modalMessageData: IModalData;
  setModalMessageData?: (IModalData) => void;
  modalSelectData: IModalSelectionData;
  setModalSelectData?: (IModalSelectionData) => void;
  modalAuthAfterSuccess: TAuthAfterSuccess;
  setModalAuthAfterSuccess?: (TAuthAfterSuccess) => void;
}

export const AppContext = createContext<IAppContext>({
  modalType: '',
  modalMessageData: {
    title: '',
    subTitle: '',
    buttonText: '',
  },
  modalSelectData: {
    title: '',
    subTitle: '',
    buttonCloseText: '',
    buttonActionText: '',
    buttonActionEffect: () => {},
  },
  modalAuthAfterSuccess: '',
});

export const AppContextProvider: React.FC<IAppContext> = ({
  children,
  modalType,
  modalMessageData,
  modalSelectData,
  modalAuthAfterSuccess,
}) => {
  const [stateModalType, setStateModalType] = useState(modalType);
  const [stateModalMessageData, setStateModalMessageData] =
    useState(modalMessageData);
  const [stateModalSelectData, setStateModalSelectData] =
    useState(modalSelectData);
  const [stateModalAuthAfterSuccess, setStateModalAuthAfterSuccess] = useState(
    modalAuthAfterSuccess
  );

  const setModalType = state => {
    setStateModalType(state);
  };

  const setModalMessageData = state => {
    setStateModalMessageData(state);
  };

  const setModalSelectData = state => {
    setStateModalSelectData(state);
  };

  const setModalAuthAfterSuccess = state => {
    setStateModalAuthAfterSuccess(state);
  };

  return (
    <AppContext.Provider
      value={{
        modalType: stateModalType,
        setModalType,
        modalMessageData: stateModalMessageData,
        setModalMessageData,
        modalSelectData: stateModalSelectData,
        setModalSelectData,
        modalAuthAfterSuccess: stateModalAuthAfterSuccess,
        setModalAuthAfterSuccess,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
