import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IPrevPage {
  url?: string;
  position?: number;
  scrollPosition?: number;
}

export interface IUserStore {
  geoData?: string;
  isAuthorized?: boolean;
  prevPage?: IPrevPage;
  pageScrollTo?: number;
  email?: string;
}

const initialState: IUserStore = {
  geoData: 'RU',
  isAuthorized: false,
  prevPage: {},
  pageScrollTo: 0,
  email: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setGeoData(state, action: PayloadAction<any>): void {
      state.geoData = action.payload;
    },

    setAuthorization(state, action: PayloadAction<any>): void {
      state.isAuthorized = action.payload;
    },

    setPrevPage(state, action: PayloadAction<any>): void {
      state.prevPage = action.payload;
    },

    setPageScrollTo(state, action: PayloadAction<any>): void {
      state.pageScrollTo = action.payload;
    },

    setEmailData(state, action: PayloadAction<any>): void {
      state.email = action.payload;
    },
  },
});

const { reducer: userReducer, actions: userActions } = userSlice;

export const {
  setGeoData,
  setAuthorization,
  setPrevPage,
  setPageScrollTo,
  setEmailData,
} = userActions;

export default userReducer;
