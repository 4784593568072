import axios from 'axios';
import { stringify } from 'qs';

const axiosInstance = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API_HOST}/api/v3`,
  withCredentials: false,
  paramsSerializer: params => {
    return stringify(params, { arrayFormat: 'brackets' });
  },

  headers: {
    Accept: 'application/json',
  },
});

export const httpClient = axiosInstance;
